var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"916e3352eefd0c6c8e21225055829a52953c9156"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (process.env.NEXT_PUBLIC_NODE_ENV === 'production' && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0,
    maxBreadcrumbs: 2048,
    maxValueLength: 4092,
    // When enabled, stack traces are automatically attached to all messages
    // logged. Stack traces are always attached to exceptions; however,
    // when this option is set, stack traces are also sent with messages.
    // This option, for instance, means that stack traces appear next to all
    // log messages.
    attachStacktrace: true,
  })
}

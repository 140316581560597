import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import setLanguage from 'next-translate/setLanguage'

import { I18nRegionLanguageMap } from 'constants/locale'
import { PropsWithChildrenRequired } from 'constants/typings'
import { Region } from 'generated/generated-graphql'

import { useAuth } from './UserProvider'

interface RegionProviderContextType {
  region: Region
  changeRegion: (value: Region) => void
}

const RegionContext = createContext({} as RegionProviderContextType)

export const useRegion = (): RegionProviderContextType =>
  useContext(RegionContext)

const RegionProvider = ({ children }: PropsWithChildrenRequired) => {
  const { userData } = useAuth()
  const [region, setRegion] = useState(Region.UnitedKingdom)

  useEffect(() => {
    if (userData?.region) {
      setRegion(userData.region)
      void setLanguage(I18nRegionLanguageMap[userData.region])
    }
  }, [userData?.region])

  // TODO use this to change region on landing page and pages where user is yet not authorized
  const changeRegion = useCallback(
    // eslint-disable-next-line consistent-return
    (selectedRegion: Region) => {
      setRegion(selectedRegion)
      void setLanguage(I18nRegionLanguageMap[selectedRegion])
    },
    []
  )

  const contextValue = useMemo(
    () => ({
      region,
      changeRegion,
    }),
    [region, changeRegion]
  )

  return (
    <RegionContext.Provider value={contextValue}>
      {children}
    </RegionContext.Provider>
  )
}

export default RegionProvider

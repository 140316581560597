import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Input: ComponentMultiStyleConfig = {
  parts: ['field', 'addon'],
  variants: {
    outline: {
      field: {
        px: '2',
        h: '2.5rem',
        borderColor: 'brand.gainsboro',
        borderRadius: 'sm',
        color: 'brand.darkSlateBlue',
        lineHeight: 'shorter',
        fontSize: { base: '16px', lg: 'xs' },
        fontWeight: 'normal',
        _placeholder: {
          px: '0',
          textTransform: 'none',
          color: 'brand.wildBlueYonder',
          lineHeight: 'shorter',
          fontSize: { base: '16px', lg: 'xs' },
          fontWeight: 'normal',
        },
        _focus: {
          boxShadow: 'none',
          borderWidth: '1px',
          borderColor: 'brand.darkBlueGray',
          bgColor: 'brand.ghostWhite',
        },
        _invalid: {
          bgColor: 'state.errorHover',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
      addon: {
        borderColor: 'brand.gainsboro',
        color: 'brand.darkSlateBlue',
        lineHeight: 'shorter',
        fontSize: { base: '16px', lg: 'xs' },
        fontWeight: 'normal',
      },
    },
  },
}

import { useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

export const useGooglePlacesSessionToken = () => {
  const [sessionToken, setSessionToken] = useState(uuidv4())

  const setNewSessionToken = () => {
    setSessionToken(uuidv4())
  }

  return {
    sessionToken,
    setNewSessionToken,
  }
}
